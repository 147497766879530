<template>
    <div class="home">
        <div class="bg-img">
            <div class="banner">
                <div class="container-fluid">
                    <div class="row">
                        <div class="banner-content">
                            <h1 class="do-not-translate" data-wg-notranslate="">Kiel.RefugeeCompass</h1>
                            <h5>Освітні, консультаційні та дозвіллєві заходи для біженців у Кілі</h5>
                        </div>
                    </div>
                    <div class="col-12 col-lg-6 pl-0">
                        <div class="banner-search">
                            <h6>Шукайте відповідні пропозиції:</h6>
                            <keyword-search @searchWordChanged="searchWordChanged"></keyword-search>
                        </div>
                    </div>
                </div>
            </div>

            <main-categories-section></main-categories-section>
        </div>

        <event-section role="region"></event-section>

        <div class="section footer-margin">
            <div class="container-fluid">
                <div class="row">

                    <div class="col-12 col-lg-3 col-xl-4">
                        <div class="section-head mt-lg-40">
                            <h3 class="section-head__title">Об'єкти поруч з вами</h3>
                        </div>
                        <router-link :to="{ name: 'Orte'}" v-if="!$isMobileScreen" class="btn btn-hover">Більше можливостей</router-link>
                    </div>
                    <div class="col-12 col-lg-9 col-xl-8">
                       <single-map-view></single-map-view>
                       <router-link v-if="$isMobileScreen" :to="{ name: 'Orte'}" title="Показати більше можливостей" class="btn btn-block mt30 mobile-orte-btn">Більше можливостей</router-link>
                   </div>
               </div>
           </div>
       </div>
   </div>


</template>